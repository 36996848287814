export default {
  mainColor: '#2f5427', // #3CC47C
  secondaryColor: '#d9ecd4', //'#c6a9a3'
  secondaryColorTransparent: 'rgba(217, 236, 212, 0.85)', //'#c6a9a3'
  keywords: [
    'Sabine Pölzl',
    'Gleisdorf',
    'Lifecoach',
    'Coaching',
    'Mentaltraining',
    'Lebens- und Sozialberatung',
    'Balance',
  ],
};
