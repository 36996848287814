import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScNavigation = styled.div`
  display: flex;
  padding: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 0;

  @media (max-width: 1001px) {
    display: none;
  }
`;

const getScLink = component => styled(component)`
  display: block;
  position: relative;
  color: ${globals.mainColor};
  padding: 0.5rem;
  margin: 0 0.5rem;
  text-decoration: none !important;
  z-index: -1;
  transition: all 0.3s;
  /* border-bottom: 3px solid #fff; */
  
  &::after {
    position: absolute;
    bottom: 0;
    left:0;
    transition: all 0.1s;
    background: ${globals.mainColor};
    content: " ";
    height: 3px;
    border-radius: 3px;
    width:0%;
  }
  &:hover {
  &::after {
      width: 100%;
      text-decoration: none !important;
    }
  }

  &:hover {
    /* color: #fff; //${globals.secondaryColor}; */
    /* font-weight: bold; */
  }

`;

const ScLink = getScLink(AnchorLink);

const ScEmphasizedLink = styled.a`
  display: block;
  color: ${globals.secondaryColor};
  border-radius: 2rem;
  padding: 0.35rem 0.75rem;
  margin: 0 0.5rem;
  transition: all 0.3s;
  text-decoration: none;
  z-index: -1;
  background: ${globals.mainColor};
  border: 2px solid transparent;

  &:hover {
    border: 2px solid ${globals.mainColor};
    background: transparent;
    color: ${globals.mainColor};
  }
`;

export default () => (
  <ScNavigation>
    <ScLink to="/#person">Zur Person</ScLink>
    <ScLink to="/consultation">Beratung</ScLink>
    <ScLink to="/coaching">Coaching</ScLink>
    <ScLink to="/mentaltraining">Mentaltraining</ScLink>
    <ScLink to="/nada">NADA-Akupunktur</ScLink>
    <ScLink to="/#map">Anfahrt</ScLink>
    {/* <ScLink to="/privacy-policy">Datenschutzerklärung</ScLink>
    <ScLink to="/imprint">Impressum</ScLink> */}
    <ScEmphasizedLink href="mailto:bea@lifebalance-coach.at?subject=Termin Buchen">
      Termin Buchen
    </ScEmphasizedLink>
  </ScNavigation>
);
