import {
  IoCall,
  IoCallOutline,
  IoGlobe,
  IoGlobeOutline,
  IoMailOpen,
  IoMailOpenOutline,
  IoMap,
  IoMapOutline,
} from 'react-icons/io5';
import {
  ScFlexAlignCenter,
  ScFlexCenter,
  ScHeader3,
  ScImage,
  ScHeader4,
} from './styled-components';

import { Link } from 'gatsby';
import React from 'react';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScFooter = styled.div`
  background: #eee;
  display: flex;
`;

const ScMaxWidthContainer = styled.div`
  position: relative;
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin: auto;
  z-index: 100;
`;

const ScColumns = styled.div`
  display: flex;
  /* padding: 0 1rem; */
  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
  }
`;

const ScContainer = styled.div`
  margin: 1rem;
  flex: 1;
  width: 33%;
  @media (max-width: 1000px) {
    width: 100%;
  }

  a:hover {
    text-decoration: underline !important;
  }
`;

export default () => (
  <ScFooter>
    <ScMaxWidthContainer>
      <ScColumns>
        <ScContainer>
          <ScHeader4>Sabine Pölzl</ScHeader4>
          <p>
            Psychosoziale Beratung
            <br />
            Dipl. Mental-Coach und Mental-Trainerin
            <br />
            Dipl. Lebens- und Sozialberaterin
          </p>
        </ScContainer>
        <ScContainer>
          <ScHeader4>Links</ScHeader4>
          <p>
            <Link to="/imprint">Impressum</Link>
            <br />
            <Link to="/privacy-policy">Datenschutzerklärung</Link>
          </p>
          <a href="https://www.neuropraxis-poelzl.at/">Dr. Stephan Pölzl</a>
          <br />
          <br />
          <a href="https://www.wko.at/site/ImpulsPro/start.html">
            <ScFlexAlignCenter>
              WKO ImpulsPro
              <ScImage
                style={{ width: '3.4em', height: '4.1em', marginLeft: '1em' }}
                src="pictures/Impuls_pro_neu.jpg"
              />
            </ScFlexAlignCenter>
          </a>
        </ScContainer>
        <ScContainer>
          <ScHeader4>Kontakt</ScHeader4>
          <div>
            <ScFlexAlignCenter>
              <IoMap
                style={{
                  marginRight: '0.5rem',
                  marginTop: '0.25rem',
                  alignSelf: 'flex-start',
                }}
              />
              <div>
                <a href="https://goo.gl/maps/CosGpdbaWSVBFNxa7">
                  Franz-Josef-Straße 17
                  <br /> 8200 Gleisdorf
                </a>
              </div>
            </ScFlexAlignCenter>
            <br />
            <ScFlexAlignCenter>
              <IoCall style={{ marginRight: '0.5rem' }} />
              <div>
                <a href="tel:+43 676 377 09 27">+43 676 377 09 27</a>
              </div>
            </ScFlexAlignCenter>
            <ScFlexAlignCenter>
              <IoMailOpen style={{ marginRight: '0.5rem' }} />
              <div>
                <a href="mailto:bea@lifebalance-coach.at">
                  bea@lifebalance-coach.at
                </a>
              </div>
            </ScFlexAlignCenter>

            <ScFlexAlignCenter>
              <IoGlobeOutline style={{ marginRight: '0.5rem' }} />
              <div>
                <a href="http://www.lifebalance-coach.at/">
                  www.lifebalance-coach.at
                </a>
              </div>
            </ScFlexAlignCenter>
          </div>
        </ScContainer>
      </ScColumns>
      <p style={{ padding: '1rem', margin: '1rem', fontSize: '8pt' }}>
        © 2021 by Sabine Pölzl
      </p>
    </ScMaxWidthContainer>
  </ScFooter>
);
